.header {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100px;
    backdrop-filter: blur(10px);
    transition: var(--transition);
    filter: none !important;
    pointer-events: auto !important;
    user-select: auto !important;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0px auto;
    max-width: 1600px;
    min-height: 100vh;
    padding: 0 150px 100px 150px;
}

.logo {
    height: 50px;
    width: auto;
}

.navBar {
    display: flex;
    padding: 0 50px;
    justify-content: space-between;
    position: relative;
    align-items: center;
    width: 100%;
    height: var(--nav-height);
}

.navBar :global(#hamburgerMenu) {
    display: none;
}

.navBar :global(#sideMenu) {
    display: none;
}

.logoWrapper {
    display: flex;
}

.navLink {
    background-color: transparent;
    border-radius: var(--border-radius);
    padding: 0.75rem 1rem;
    font-family: var(--font-mono);
    line-height: 1;
    cursor: pointer;
    margin-left: 5px;
    font-size: var(--fz-xs);
    transition: var(--transition);
    color: var(--colors-font) !important;
}

.navLink:hover {
    border: 1px solid var(--colors-font);
    color: var(--colors-font) !important;
}

.closedSideMenu,
.openSideMenu {
    background-color: var(--colors-surface);
    height: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.25rem;
}

.closedSideMenu {
    /* closed */
    width: 0;
    padding: 100px 0;
    box-shadow: 0;
}

.openSideMenu {
    /* open */
    width: 40%;
    padding: 100px 60px;
    box-shadow: 21px 2px 45px 13px rgba(0, 0, 0, 0.75);
}

.closedSideMenu .navLink,
.openSideMenu .navLink {
    width: 100%;
    margin-bottom: 20px;
}

.closeIcon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 30px 17px;
}

@media (max-width: 1024px) {
    .main {
        padding: 0 60px 100px 60px;
    }
}

@media (max-width: 768px) {
    .navBar {
        padding: 0 20px;
    }

    .navBar :global(#navRow) {
        display: none;
    }

    .navBar :global(#hamburgerMenu) {
        display: block;
    }

    .main {
        padding: 0 30px 100px 30px;
    }
}

@media (max-width: 425px) {
    .header {
        height: 80px;
    }

    .logo {
        height: 35px;
        width: auto;
    }

    .main {
        padding: 0 20px 100px 20px;
    }
}
