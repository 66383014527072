.projectPaneContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.projectWrapper {
    background-color: var(--colors-surface);
    width: 100%;
}

.projectContainer {
    padding: 20px;
}
