.p {
    margin: 0;
    font-size: 1rem;
}

.accordionWrapper {
    width: 100%;
}

.accordionEntry {
    margin: 10px auto;
}

.accordionHeader {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: var(--colors-secondary-surface);
    cursor: pointer;
}

.accordionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    width: 90%;
}

.accordionContentActive {
    overflow: hidden;
    padding: 30px 20px;
    margin-top: 10px;
    font-size: 1rem;
    background-color: var(--colors-surface);
}

.accordionContentActive p:first-child {
    margin-top: 0;
}

.accordionContentActive ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-left: 1rem;
}

.accordionContentActive li {
    margin-bottom: 0.5rem;
}

.accordionContentInactive {
    height: 0px;
    overflow: hidden;
    margin: 0;
    padding: 0 20px;
}

.openCloseIconContainer {
    align-self: center;
}

.companySpan {
    color: var(--colors-primary);
    display: inline-block;
}

@media (max-width: 768px) {
    .dateSpan {
        display: flex;
        flex-direction: column;
    }

    .dateDash {
        display: none;
    }
}
