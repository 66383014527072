.link {
    color: var(--colors-primary);
    position: relative;
    display: inline-block;
}

.link:after {
    content: "";
    display: block;
    background-color: var(--colors-primary);
    width: 0;
    height: 1px;
    bottom: 0.37em;
    transition: var(--transition);
}

.link:hover {
    color: var(--colors-primary);
}

.link:hover:after {
    width: 100%;
}

.text {
    color: var(--colors-primary);
    display: inline-block;
}
