.theme-manager__container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.theme-manager__menu {
    margin-top: 10px;
    list-style-type: none;
    padding: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: var(--border-radius) var(--border-radius) 0
        var(--border-radius);
    overflow: hidden;
    border: solid 1px var(--colors-font);
}

.theme-manager__menu li {
    cursor: pointer;
}

.theme-manager__menu li:hover {
    background-color: #f1f1f1;
}

.theme-manager__theme-button {
    color: var(--colors-font);
    background-color: var(--colors-background);
    border: none;
    padding: 10px 30px;
    width: 100%;
    text-align: start;
    text-decoration: none;
    display: inline-block;
}

.theme-manager__theme-button:hover {
    /* add a filter that lightens the button */
    filter: brightness(120%);
}

.theme-manager__theme-button__accent-box {
    background-color: var(--colors-primary);
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.theme-manager__theme-button__accent-box--active {
    box-shadow: 0 0 10px 3px var(--colors-primary);
}
